import { CharacterPageContainer, TopLeftLogo } from "../templates/CharacterPage/style";
import { DMM, Footer, NavbarMobile, NavbarPC, ScrollToTop } from "./index";
import { navigate } from "gatsby";
import React from "react";

export const AnnouncementContainer = ({ children, append }) => {
  return (
    <>
      <CharacterPageContainer id="character-container">
        <DMM />
        <NavbarPC toRoot={true} />
        <NavbarMobile toRoot={true} />
        <TopLeftLogo id="back-to-home" onClick={() => navigate("/")}>
          <img src={require(`../assets/images/sub-logo-jp.png`).default} alt="logo" />
        </TopLeftLogo>
        <div className="mx-auto max-w-[1280px] mt-32 lg:mt-44">
          <h1
            className="
                        grid text-[length:min(9vw,116px)] leading-normal lg:leading-normal
                        max-w-full w-[90vw] mx-auto announcement-shadow
                    ">
            <img
              className="col-span-full row-span-full"
              src={require(`../assets/images/announcement-header.png`).default}
              alt="announcement-header"
            />
            <div className="col-span-full row-span-full flex items-center justify-center pt-[min(6.25vw,72px)]">
              お知らせ
            </div>
          </h1>
          <div className="px-2 mt-4 lg:mt-36">
            <div className="bg-[#F0EDEB] text-black px-2.5 py-8 md:p-[min(13vw,168px)]">
              {children}
            </div>
          </div>
        </div>
        {append && <div className="text-center pt-16">{append}</div>}
      </CharacterPageContainer>
      <Footer />
      <ScrollToTop />
    </>
  );
};
