import React, { useMemo } from "react";
import { graphql, navigate } from "gatsby";
import { AnnouncementContainer } from "../../components/AnnouncementContainer";
import { MoreButton } from "../../components/MoreButton";

export default function Announcement({ data }) {
  const { frontmatter } = data.markdownRemark;
  const BackButton = useMemo(
    () => (
      <MoreButton onClick={() => navigate("/announcements")}>
        <span className="flex items-center">
          <svg
            className="mr-4"
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9999 17L0.999999 9.0002L11 1" stroke="white" />
          </svg>
          BACK TO LIST
        </span>
      </MoreButton>
    ),
    []
  );

  return (
    <AnnouncementContainer append={BackButton}>
      <time className="block font-bold text-xs md:text-2xl mb-2">{frontmatter.date}</time>
      <h2 className="text-primary-100 font-bold text-sm md:text-4xl">{frontmatter.title}</h2>
      <article
        className="
                                max-w-none mt-12 lg:mt-20 prose prose-sm lg:prose-xl prose-headings:text-primary-100
                                prose-headings:text-sm lg:prose-headings:text-xl
                                "
        dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
      />
    </AnnouncementContainer>
  );
}

export const query = graphql`
  query Announcement($slug: String!) {
    markdownRemark(frontmatter: { type: { eq: "announcement" }, slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
      }
    }
  }
`;
