import React from "react";

export const MoreButton = ({ onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="max-w-full inline-grid justify-items-center items-center">
      <img
        width={311}
        height={92}
        src="/more-button.png"
        alt="more"
        className="col-span-full row-span-full"
      />
      <span className="col-span-full row-span-full block text-2xl font-bold pb-4">{children}</span>
    </button>
  );
};
